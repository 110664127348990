import { SignInOptions, signIn } from 'next-auth/react';

import { QueryKey } from '../enums/queryKey.enum';

export const signInUser = (
  signInOption: SignInOptions = {},
  preSignInFn?: () => void,
) => {
  localStorage.removeItem('autoGenerate');
  preSignInFn && preSignInFn();
  signIn('google', signInOption);
};

export const getQueryParameterValue = (
  url: string,
  key: QueryKey,
): string | null => {
  try {
    const urlObj = new URL(url);
    return urlObj.searchParams.get(key);
  } catch {
    return null;
  }
};
