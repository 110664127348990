
import { request } from '../../../utils/fetch';

async function getCheckoutSession(productId: string) {
  console.log('Get checkout session');

  const url = new URL(
    `/api/v1/payments?productId=${productId}`,
    process.env.NX_EXTERNAL_BACKEND_URL
  );

  const response = await request(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const data = await response.json();
    console.error(data);
    throw new Error('Something went wrong.');
  }

  return response.json();
}

export { getCheckoutSession };
