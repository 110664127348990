/* eslint-disable react-hooks/rules-of-hooks */
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import * as PaymentApi from './Payment/Payment';

export const checkoutSession = (
  onSuccess
): UseMutationResult<unknown, unknown, string> => {
  return useMutation((productId) => PaymentApi.getCheckoutSession(productId), {
    onSuccess,
  });
};
