import { Product } from '../../../types/product';
import { request } from '../../../utils/fetch';

async function getProductList() {
  console.log('Get productList list');

  const url = new URL(
    `/api/v1/products?active=true`,
    process.env.NX_EXTERNAL_BACKEND_URL,
  );

  const response = await request(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const data = await response.json();
    console.error(data);
    throw new Error('Something went wrong.');
  }

  return response.json() as Promise<Array<Product>>;
}

export { getProductList };
