import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { BlogPost } from '../constants/blogs';

import ImageCard from './molecules/ImageCard';

interface Props {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  post: BlogPost;
}

function ImageModal(props: Readonly<Props>) {
  const { isOpen, setIsOpen, post } = props;

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50"
        onClose={() => setIsOpen(false)}
      >
        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="font-inter flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative m-auto min-w-[400px] transform overflow-hidden text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl">
                <button
                  className="absolute top-0 right-0 z-50 m-4"
                  onClick={() => setIsOpen(false)}
                >
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-5 w-5 cursor-pointer text-white hover:text-gray-300"
                  />
                </button>
                <div className="relative flex flex-1 flex-col justify-end overflow-hidden rounded-2xl px-4 pt-80 pb-8 sm:h-[800px] sm:pt-48 lg:pt-80">
                  <div className="flex justify-center">
                    <ImageCard
                      image={{
                        title: post.title,
                        imgSrc:
                          process.env.NEXT_PUBLIC_GOOGLE_CDN + post.postImage,
                        caption: post.headline,
                        date: post.date,
                      }}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ImageModal;
