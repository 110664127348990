import Image from 'next/image';
import Link from 'next/link';

const SocialMediaLinks = () => (
  <div className="flex items-center justify-end gap-3">
    <Link
      href="https://www.facebook.com/lessonplannerph"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src="/images/landing/facebook.svg"
        alt="logo"
        width={25}
        height={25}
      />
    </Link>
    {/* <Link
      href="https://www.instagram.com/lessonplannerph"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src="/images/landing/instagram.svg"
        alt="logo"
        width={25}
        height={25}
      />
    </Link> */}
    {/* <Link
      href="https://www.linkedin.com/lessonplannerph"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src="/images/landing/linkedin.svg"
        alt="logo"
        width={25}
        height={25}
      />
    </Link> */}
    <Link
      href="https://www.youtube.com/@lessonplannerph"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src="/images/landing/youtube.svg"
        alt="logo"
        width={25}
        height={25}
      />
    </Link>
    <Link
      href="https://www.tiktok.com/@lessonplannerph?_t=8ewM5DJ7BgH&_r=1"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src="/images/landing/tiktok.svg"
        alt="logo"
        width={25}
        height={25}
      />
    </Link>
  </div>
);

export default SocialMediaLinks;
