export interface BlogPost {
  id: number;
  date: string;
  authorImage: string;
  author: string;
  title: string;
  headline: string;
  postImage: string;
};

export const blogPosts: BlogPost[] = [
  {
    id: 1,
    date: 'September 5, 2024',
    authorImage: '',
    author: 'Lesson Planner PH',
    title: 'Adopt-a-School Launch',
    headline: 'LP Launches the Adopt-a-School Program for Teachers\' Month 2024',
    postImage: '/images/posts/adopt-a-school.jpg'
  },
  {
    id: 2,
    date: 'August 15, 2024',
    authorImage: '',
    author: 'Lesson Planner PH',
    title: 'St. Dominic Savio International School',
    headline: 'Saint Dominic Savio International School is Now Lesson Planner PH-Ready!',
    postImage: '/images/posts/st-dominic.jpg'
  },
  {
    id: 3,
    date: 'August 7, 2024',
    authorImage: '',
    author: 'Lesson Planner PH',
    title: 'MOU Signing with DepEd QA R7',
    headline: 'DepEd Region 7 & Lesson Planner PH Join Forces for Education Innovation',
    postImage: '/images/posts/mou-signing.jpg'
  },
  {
    id: 4,
    date: 'July 11, 2024',
    authorImage: '',
    author: 'Lesson Planner PH',
    title: 'Partnership with Cerebro',
    headline: 'Lesson Planner PH Partners with Cerebro to Elevate Classroom Learning',
    postImage: '/images/posts/cerebro.jpg'
  },
  {
    id: 5,
    date: 'July 22, 2024',
    authorImage: '',
    author: 'Lesson Planner PH',
    title: 'Singapore School AI Talk',
    headline: 'Roland Bautista, Lesson Planner PH Co-Founder and CCSO, Talks AI in Education at Singapore School Cebu',
    postImage: '/images/posts/singapore-school.jpg'
  },
  {
    id: 6,
    date: 'June 21, 2024',
    authorImage: '',
    author: 'Lesson Planner PH',
    title: 'DICT SGF Demo Day',
    headline: 'Lesson Planner PH Joins DICT’s Startup Grant Fund Pioneer Cohort!',
    postImage: '/images/posts/dict.jpg'
  },
];
